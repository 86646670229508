import { forwardRef, useId } from 'react';
import ErrorMessage from './error-message';
import Label from './label';

import TextArea, { TextAreaProps } from './textarea';

type TextAreaInputProps = TextAreaProps & {
  errorMessage?: string;
  label: string;
};

const TextAreaInput = forwardRef<HTMLTextAreaElement, TextAreaInputProps>(
  (props, ref) => {
    const {
      errorMessage, isError, label, id: id_, name, ...rest
    } = props;
    const id = useId();
    return (
      <div className="flex flex-col gap-y-2">
        <Label htmlFor={id}>{label}</Label>

        <TextArea isError={isError} name={name} id={id} {...rest} ref={ref} />
        {isError && errorMessage ? (
          <ErrorMessage errorMessage={errorMessage} />
        ) : null}
      </div>
    );
  },
);

export default TextAreaInput;
