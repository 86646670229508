/* eslint-disable react/button-has-type */
import { ComponentProps, forwardRef } from 'react';
import mergeClass from '../utils/mergeClass';

interface Props extends ComponentProps<'button'> {
  fullWidth?: boolean;
}

const Button = forwardRef<HTMLButtonElement, Props>((props, ref) => {
  const {
    children, className, fullWidth, ...rest
  } = props;

  return (
    <button
      className={mergeClass(
        'rounded-md border border-transparent ',
        'bg-indigo-600 py-2 px-4 text-sm font-medium text-white ',
        'shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 ',
        'focus:ring-indigo-500 focus:ring-offset-2',
        fullWidth && 'w-full',
        className,
      )}
      {...rest}
      ref={ref}
    >
      {children}
    </button>
  );
});

export default Button;
