import { BsFillCalendarDateFill } from 'react-icons/bs';
import { TemplateItem } from '../../../types/template.type';

interface Props {
  article: TemplateItem;
}

function ArticleCard(props: Props) {
  const { article } = props;

  return (
    <div className="rounded-md border-2 border-green-900/5 h-full flex p-4 bg-gray-100 gap-x-6 shadow-xl">
      <div className="w-full flex flex-col gap-y-2">
        <div className="flex w-full justify-between">
          <h1 className="text-primary-dark font-semibold">{article.title}</h1>
          <h2 className="text-white bg-primary-medium px-4 rounded-full font-semibold max-h-6">
            {article.status}
          </h2>
        </div>
        <div>
          <p className="text-sm text-primary-dark">{article.excerpt}</p>
        </div>
        <div>
          <div className="flex items-center gap-x-2">
            <BsFillCalendarDateFill />
            <p className="text-sm">
              {`Published on ${article.createdAt}`}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
export default ArticleCard;
