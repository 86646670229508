/* eslint-disable react/no-array-index-key */
/* eslint-disable no-underscore-dangle */
// import { useState } from 'react';
// import { useLocation } from 'react-router-dom';
import CustomNavLink from './CustomNavLink';
// import API from '../../utils/axios';
import { useAllTemplates } from '../../hooks/template';

function Sidebar() {
  // const [sidebarItems, setSidebarItems] = useState<
  //   Array<Record<string, string>>
  // >([]);

  const { data } = useAllTemplates();

  return (
    <div className="px-2 py-8 | lg:py-4 | 2xl:p-10">

      <ul className="mt-14 flex flex-col gap-y-6 | md:gap-y-8 | 2xl:gap-y-12">
        <CustomNavLink to="/template" label="template" />
        {data && data.data.length ? (
          <>
            {data.data.map((item) => (
              <CustomNavLink
                key={item._id}
                to={`/template/${item._id}`}
                label={item.name}
              />
            ))}
          </>
        ) : null}
        <CustomNavLink to="/applications" label="applications" />
      </ul>
    </div>
  );
}

export default Sidebar;
