import CreatableSelect from 'react-select/creatable';
import { Controller, UseControllerProps } from 'react-hook-form';
import { useId } from 'react';
import Label from './label';
import ErrorMessage from './error-message';

export interface Props extends UseControllerProps<any, any> {
  label: string;
}

export default function MultiInput(props: Props) {
  const { label, name, ...rest } = props;
  const id = useId();
  return (
    <Controller
      name={name}
      render={({ field, formState: { errors } }) => (
        <div className="flex flex-col gap-y-2">
          <Label htmlFor={id}>{label}</Label>
          <CreatableSelect
            id={id}
            {...field}
            isMulti
            // eslint-disable-next-line max-len
            isValidNewOption={(inputValue, values) => !values.find((opt) => opt.label === inputValue)}
            isClearable
          />
          {!!errors[name]
          && errors[name]?.message
          && typeof errors[name]?.message === 'string' ? (
            <ErrorMessage errorMessage={errors[name]?.message as any} />
            ) : null}
        </div>
      )}
      {...rest}
    />
  );
}
