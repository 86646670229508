import React, { useState } from 'react';
import jwtDecode from 'jwt-decode';
import { useNavigate } from 'react-router-dom';

import { useAppDispatch } from '../app/hook';
import { setCredentials } from '../features/auth/authSlice';
import API, { setApiAuthHeader } from '../utils/axios';

function Login() {
  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  const [loginCredentials, setLoginCredentials] = useState({
    email: '',
    password: '',
  });

  const { email, password } = loginCredentials;

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLoginCredentials((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const handleLogin = async () => {
    try {
      const res = await API.post('/auth/login', loginCredentials);
      const { token } = res.data;
      console.log(token);

      const decoded: any = jwtDecode(token);
      const { name, id, companyId } = decoded;

      if (id) {
        setApiAuthHeader(token);
        dispatch(
          setCredentials({
            name,
            id,
            token,
            companyId,
          }),
        );
        navigate('/template');
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className="grid md:grid-cols-2 grid-cols-1 h-screen w-screen">
      <div className="flex flex-col items-center justify-center p-10">
        <div className="flex flex-col gap-10 md:w-[550px] w-full">
          <div className="flex flex-col font-bold text-4xl">
            <h2 className="text-ss-red">Hello,</h2>
            <h2 className="text-ss-gray">Welcome back!</h2>
          </div>
          <label htmlFor="email" className="flex flex-col gap-y-2 text-ss-gray">
            Your Email Address
            <input
              className="px-4 py-2 border border-ss-gray rounded-lg"
              type="text"
              name="email"
              id="email"
              value={email}
              onChange={handleChange}
            />
          </label>

          <label
            htmlFor="password"
            className="flex flex-col gap-y-2 text-ss-gray"
          >
            Your Password
            <input
              className="px-4 py-2 border border-ss-gray rounded-lg"
              type="password"
              name="password"
              id="password"
              value={password}
              onChange={handleChange}
            />
          </label>
          <button
            className="py-2 px-8 rounded mt-2 bg-ss-red text-white font-bold"
            type="button"
            onClick={handleLogin}
          >
            Login
          </button>
        </div>
      </div>
      <div className="flex flex-col gap-16 items-center justify-center bg-ss-red h-screen">
        <h1 className="text-white font-bold text-center text-5xl max-w-[500px]">
          Content Management System
        </h1>
        <img src="/images/login.svg" alt="login" className="w-1/2" />
      </div>
    </div>
  );
}
export default Login;
