/* eslint-disable react/no-array-index-key */
import useSWR from 'swr';
import { fetcher } from '../../utils/axios';

import AppCard from './app-card';

export default function Applications() {
  const { data, mutate, error } = useSWR(() => '/applications', fetcher);
  const loading = !data && !error;

  console.log('🚀 ~ file: applications.tsx:8 ~ Applications ~ data', data);

  if (loading && !data) {
    return <div>loading...</div>;
  }

  return (
    <div className="py-20 px-20 w-full h-full">
      <div className="grid grid-cols-3 gap-4">
        {data.map((application: any, i: number) => (
          <AppCard key={i} data={application} />
        ))}
      </div>
    </div>
  );
}
