/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-no-useless-fragment */
import {
  forwardRef, Fragment, useEffect, useId,
} from 'react';
import {
  UseFormRegister,
  UseFormSetValue,
  UseFormWatch,
} from 'react-hook-form';
import SelectInput from '../../form-prirmitives/select-Input';
import TextInput, {
  Props as TextInputProps,
} from '../../form-prirmitives/text-input';
import TextAreaInput from '../../form-prirmitives/textarea-input';
import { TemplateField } from '../../types/template.type';
import API from '../../utils/axios';

type props = TemplateField & {
  register?: UseFormRegister<any>;
  errors?: any;
  value?: string | string[];
  watch?: UseFormWatch<any>;
  setValue?: UseFormSetValue<any>;
};

const inputTypes = {
  Text: 'text',
  Date: 'date',
  Email: 'email',
  Password: 'password',
  Number: 'number',
  File: 'file',
};

type ImageInputProps = TextInputProps & {
  watch?: UseFormWatch<any>;
  formName: string;
  updatedValue?: string[];
};

const ImageInput = forwardRef<HTMLInputElement, ImageInputProps>(
  (props, ref) => {
    const {
      watch, formName, updatedValue, ...rest
    } = props;
    const image: FileList | string[] | undefined = watch
      ? watch(formName)
      : undefined;

    const data = image?.length
      ? image
      : updatedValue?.length
        ? updatedValue
        : undefined;
    return (
      <>
        <TextInput accept="image/*" type="file" {...rest} ref={ref} />
        <div className="flex flex-col gap-y-2">
          {data?.length
            ? [...data].map((item, index) => (
              <img
                src={
                    typeof item === 'string' ? item : URL.createObjectURL(item)
                  }
                key={
                    typeof item === 'string' ? item : `${item.name}-${index}`
                  }
                alt=""
              />
            ))
            : null}
        </div>
      </>
    );
  },
);

type FileInputProps = TextInputProps & {
  watch?: UseFormWatch<any>;
  formName: string;
  updatedValue?: string[];
};

const FileInput = forwardRef<HTMLInputElement, FileInputProps>((props, ref) => {
  const {
    watch, formName, updatedValue, ...rest
  } = props;
  const file: FileList | string[] | undefined = watch
    ? watch(formName)
    : undefined;

  const data = file?.length
    ? file
    : updatedValue?.length
      ? updatedValue
      : undefined;
  return (
    <>
      <TextInput type="file" {...rest} ref={ref} />
      <div className="flex flex-col gap-y-2">
        {data?.length
          ? [...data].map((item, index) => {
            console.log(item);
            return <>test</>;
          })
          : null}
      </div>
    </>
  );
});

export default function FormRenderer(props: props) {
  const {
    isRequired,
    label,
    type,
    options,
    register,
    errors,
    value,
    watch,
    setValue,
    isMulti,
    limit,
  } = props;

  const name = `customFields.${label}`;
  const isError = !!(
    errors
    && errors.customFields
    && errors.customFields[label]
  );
  const errorMessage = errors
    && errors.customFields
    && errors.customFields[label]
    && errors.customFields[label].message
    ? errors.customFields[label].message
    : '';

  const isMediaFieldRequired = !((type === 'Media' || type === 'File') && value);

  const defaultProps = {
    isError,
    errorMessage,
    label,
    ...(register && {
      ...register(name, {
        ...(isRequired
          && isMediaFieldRequired && { required: 'this field is required' }),
      }),
    }),
    ...(value && type !== 'Media' && type !== 'File' && { defaultValue: value }),
  };

  useEffect(() => {
    if (type === 'Media' && value && setValue) {
      setValue(name, value);
    }
  }, []);

  switch (true) {
    case type === 'Select':
      return (
        <SelectInput {...defaultProps}>
          <option value="">select a value </option>
          {options?.map((item) => (
            <option key={item.label} value={item.value}>
              {item.label}
            </option>
          ))}
        </SelectInput>
      );

    case type === 'Text'
      || type === 'Date'
      || type === 'Email'
      || type === 'Password'
      || type === 'Number':
      return (
        <TextInput
          type={inputTypes[type as keyof typeof inputTypes]}
          {...defaultProps}
        />
      );

    case type === 'Rich Text':
      return <TextAreaInput {...defaultProps} />;

    case type === 'CheckBox':
      return (
        <TextInput
          type="checkbox"
          {...defaultProps}
          {...(value
            && typeof value === 'boolean' && { defaultChecked: value })}
        />
      );

    case type === 'Text Area':
      return <TextAreaInput {...defaultProps} />;

    case type === 'Media':
      return (
        <>
          <ImageInput
            updatedValue={
              value && typeof value !== 'string' ? value : undefined
            }
            formName={name}
            watch={watch}
            multiple={isMulti}
            {...defaultProps}
          />

          {/* hidden field to get the values of the limit while submitting the form */}
          {limit ? (
            <input
              {...(register && register(`limits.${label}`))}
              name={`limits.${label}`}
              value={limit}
              hidden
            />
          ) : null}
        </>
      );

    case type === 'File':
      return (
        <>
          <FileInput
            updatedValue={
              value && typeof value !== 'string' ? value : undefined
            }
            formName={name}
            watch={watch}
            {...defaultProps}
          />
        </>
      );

    default:
      return null;
  }
}
