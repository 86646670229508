/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable no-restricted-syntax */
/* eslint-disable import/prefer-default-export */
import { UseFormReset } from 'react-hook-form';
import { toast } from 'react-hot-toast';
import { FileUploadResponse } from '../types/common';
import { DefaultInputTypes } from '../types/template.type';
import API from '../utils/axios';
import { useTemplateItem } from './template';

type OtherArgs =
  | {
      reset: UseFormReset<DefaultInputTypes>;
      templateId: any;
      templateItemId?: never;
      isEditMode?: never;
      mutate?: never;
    }
  | {
      isEditMode: true;
      templateItemId: any;
      templateId?: never;
      reset?: never;
      mutate: ReturnType<typeof useTemplateItem>['mutate'];
    };

type SubmitHandlerArgs = { data: DefaultInputTypes } & OtherArgs;

// TODO: refactor this hook
export const useTemplateFormSubmit = () => {
  const submitHandler = async (data_: SubmitHandlerArgs) => {
    const {
      data, isEditMode, mutate, reset, templateId, templateItemId,
    } = data_;
    if (!data.content) {
      toast.error('content field is required');
      return;
    }

    try {
      const loadingToast = toast.loading('submitting the form...', {
        duration: Infinity,
        id: 'form-loading',
      });
      const formData = { ...data };

      if (formData.seo.keyword?.length) {
        formData.seo.keyword.map(({ label, value }) => ({ label, value }));
      } else {
        delete formData.seo.keyword;
      }

      const images: { field: string; file: File }[] = [];

      for (const [key, value] of Object.entries(formData.customFields)) {
        if (value && value.length && typeof value === 'object') {
          if (formData?.limits && formData.limits[key]) {
            const limit = Number(formData.limits[key]);

            if (value.length < limit) {
              toast.dismiss(loadingToast);
              toast.error(
                `${key} shouldn't be less than the limit of ${limit}`,
              );
              return;
            }

            if (value.length > limit) {
              toast.dismiss(loadingToast);
              toast.error(
                `${key} shouldn't be greater than the limit of ${limit}`,
              );
              return;
            }
          }
          for (const file of value) {
            if (typeof file !== 'string' && file.type.startsWith('image/')) {
              images.push({ field: key, file });
            }
          }
        }
      }

      if (images.length) {
        const multiPartFormData = new FormData();
        images.forEach((image) =>
          multiPartFormData.append(image.field, image.file, image.file.name));

        const req = await API.post<{
          files: FileUploadResponse[];
        }>('/file-upload/many', multiPartFormData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });

        const { data: imagesListData } = req;

        const imageList = imagesListData.files.reduce<Record<string, string[]>>(
          (prev, curr) => {
            if (prev[curr.fieldName]) {
              prev[curr.fieldName].push(curr.fileName);
            } else {
              prev[curr.fieldName] = [curr.fileName];
            }

            return prev;
          },
          {},
        );

        const prevValue = { ...formData.customFields };

        formData.customFields = { ...prevValue, ...imageList };
      }

      // file uploads
      const files: { field: string; file: File }[] = [];

      for (const [key, value] of Object.entries(formData.customFields)) {
        if (value && value.length && typeof value === 'object') {
          for (const file of value) {
            if (typeof file !== 'string' && !file.type.startsWith('image/')) {
              files.push({ field: key, file });
            }
          }
        }
      }

      if (files.length) {
        const multiPartFormData = new FormData();
        files.forEach((file) =>
          multiPartFormData.append(file.field, file.file, file.file.name));

        const req = await API.post<{
          files: FileUploadResponse[];
        }>('/file-upload/many', multiPartFormData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });

        const { data: fileListData } = req;

        const fileList = fileListData.files.reduce<Record<string, string[]>>(
          (prev, curr) => {
            if (prev[curr.fieldName]) {
              prev[curr.fieldName].push(curr.fileName);
            } else {
              prev[curr.fieldName] = [curr.fileName];
            }

            return prev;
          },
          {},
        );

        const prevValue = { ...formData.customFields };

        formData.customFields = { ...prevValue, ...fileList };
      }

      // delete limits keys we won't need this while submitting the form
      delete formData.limits;

      if (isEditMode) {
        await API.patch('/template-items', {
          ...formData,
          templateItemId,
        });
        toast.dismiss(loadingToast);
        toast.success('Success: template item updated');
        mutate();
      } else {
        await API.post('/template-items', { ...formData, templateId });
        reset();
        toast.dismiss(loadingToast);
        toast.success('Success: template item added');
      }
    } catch (error) {
      toast.dismiss('form-loading');
      toast.error('Error: Bad Request');
      console.error(error);
    }
  };

  return { submitHandler };
};
