/* eslint-disable import/prefer-default-export */
import useSWR from 'swr';
import { encode, decode } from 'qss';
import { Template, TemplateItem } from '../types/template.type';
import { fetcher } from '../utils/axios';

interface useAllTemplatesReturn {
  data: Template[];
}

interface useAllTemplateItemsReturn {
  data: TemplateItem[];
}

interface useTemplateItemReturn {
  data: TemplateItem;
}

interface useTemplateReturn {
  data: Template;
}

export function useAllTemplates() {
  const { data, mutate } = useSWR<useAllTemplatesReturn>('/templates', fetcher);

  return { data, mutate };
}

export function useAllTemplateItems(id?: string, params?: any) {
  const { data, mutate, error } = useSWR<useAllTemplateItemsReturn>(
    () => (id ? `/template-items/${id}${encode(params, '?')}` : null),
    fetcher,
  );
  const loading = !data && !error;

  return {
    data,
    loading,
    error,
    mutate,
  };
}

export function useTemplateItem(id?: string) {
  const { data, mutate, error } = useSWR<useTemplateItemReturn>(
    () => (id ? `/template-items/item/${id}` : null),
    fetcher,
  );
  const loading = !data && !error;

  return {
    data,
    loading,
    error,
    mutate,
  };
}

export function useTemplate(id?: string) {
  const { data, mutate, error } = useSWR<useTemplateReturn>(
    () => (id ? `/templates/${id}` : null),
    fetcher,
  );
  const loading = !data && !error;

  return {
    data,
    loading,
    error,
    mutate,
  };
}
