/* eslint-disable jsx-a11y/label-has-associated-control */
import { ComponentProps, ReactNode } from 'react';
import mergeClass from '../utils/mergeClass';

interface Props extends ComponentProps<'label'> {
  children: ReactNode;
  htmlFor: string;
}

export default function Label(props: Props) {
  const {
    className, children, htmlFor, ...rest
  } = props;

  return (
    <label
      htmlFor={htmlFor}
      className={mergeClass('text-sm font-medium text-gray-700', className)}
      {...rest}
    >
      {children}
    </label>
  );
}
