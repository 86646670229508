import { Route, Routes } from 'react-router-dom';

import Layout from './layouts/Layout';
import AddArticle from './pages/AddArticle';
import Login from './pages/Login';
import PrivateRoute from './routes/PrivateRoute';
import ArticlePage from './pages/ArticlePage';
import ArticleContainer from './pages/ArticleContainer';
import ArticleView from './components/article/ArticleView';
import AddTemplate from './pages/AddTemplate';
import TemplateContainer from './pages/TemplateContainer';
import { useAppDispatch } from './app/hook';
import { setApiAuthHeader } from './utils/axios';
import { setCredentials } from './features/auth/authSlice';
import Applications from './pages/applications/applications';

function App() {
  const dispatch = useAppDispatch();

  const authenticate = () => {
    const login = localStorage.getItem('login');
    if (login) {
      const {
        name, id, token, companyId,
      } = JSON.parse(login);

      setApiAuthHeader(token);
      dispatch(
        setCredentials({
          name,
          id,
          token,
          companyId,
        }),
      );
    }
  };
  authenticate();

  return (
    <Routes>
      {/* Public Routes */}
      <Route path="/login" element={<Login />} />

      {/* Private Routes */}
      <Route element={<Layout />}>
        <Route path="/" element={<PrivateRoute />}>
          <Route path="/template-item" element={<ArticleContainer />}>
            <Route
              path=":templateItemId"
              element={<ArticleView />}
            />
          </Route>
          <Route path="/template" element={<TemplateContainer />}>
            <Route index element={<AddTemplate />} />
            <Route path=":templateId" element={<ArticlePage />} />
            <Route path=":templateId/new" element={<AddArticle />} />
          </Route>
          <Route path="applications" element={<Applications />} />
        </Route>
      </Route>
    </Routes>
  );
}

export default App;
