import { ComponentProps, forwardRef } from 'react';
import mergeClass from '../utils/mergeClass';

export interface Props extends ComponentProps<'select'> {
    isError?: boolean;
}

const Select = forwardRef<HTMLSelectElement, Props>((props, ref) => {
  const {
    children, isError, className, ...rest
  } = props;
  return (
    <select
      className={mergeClass(
        'form-select py-2 px-3 border rounded-md w-full focus:outline-none text-gray-500',
        isError
          ? 'focus:ring-red-500 focus:border-red-500 border-red-500'
          : 'focus:ring-indigo-500 focus:border-indigo-500 border-gray-300',
        'shadow-sm sm:text-sm',
        className,
      )}
      ref={ref}
      {...rest}
    >
      {children}
    </select>
  );
});

export default Select;
