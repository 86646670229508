import axios from 'axios';

const API = axios.create({
  baseURL: 'https://api-cms.salessuite.global/',
});

export const setApiAuthHeader = (token: string) => {
  if (token) {
    API.defaults.headers.common.Authorization = `Bearer ${token}`;
  } else {
    delete API.defaults.headers.common.Authorization;
  }
};

export default API;

export const fetcher = (url: string) => API.get(url).then((res) => res.data);
