/* eslint-disable max-len */

import { SubmitHandler, useForm } from 'react-hook-form';
import { Navigate, useParams } from 'react-router-dom';
import DefaultFields from '../components/common/default-fields';
import FormRenderer from '../components/common/form-renderer';
import { useTemplateFormSubmit } from '../hooks/form';
import { useTemplate } from '../hooks/template';
import { DefaultInputTypes } from '../types/template.type';

function AddArticle() {
  const { templateId } = useParams();
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    control,
    reset,
    setValue,
  } = useForm<DefaultInputTypes>();

  const { submitHandler } = useTemplateFormSubmit();

  const onSubmit: SubmitHandler<DefaultInputTypes> = async (data) => {
    await submitHandler({ data, templateId, reset });
  };

  const { data, loading, error } = useTemplate(templateId);

  if (loading && !data) {
    return <div>loading...</div>;
  }

  if (!loading && !data && error) {
    return <Navigate to="/template" />;
  }

  return (
    <div className="flex flex-col py-10 items-center">
      <h2 className="text-center capitalize font-bold text-3xl mb-5">{`add new ${data?.data.name}`}</h2>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="w-3/4 flex flex-col gap-y-4"
      >
        <DefaultFields setValue={setValue} control={control} errors={errors} register={register} />
        {data?.data.customFields && !loading
          ? data.data.customFields.map((item) => (
            <FormRenderer
              {...item}
              key={item.label}
              register={register}
              errors={errors}
              watch={watch}
            />
          ))
          : null}

        <button
          type="submit"
          className="bg-green-400 uppercase text-white px-8 py-2 my-8 rounded font-semibold flex mx-auto text-center"
        >
          create
        </button>
      </form>
    </div>
  );
}
export default AddArticle;
