import { NavLink } from 'react-router-dom';

interface Props {
  to: string,
  label: string
}

function CustomNavLink(props:Props) {
  const { to, label } = props;

  return (
    <NavLink
      to={to}
      className="uppercase font-semibold text-white border-l-8 px-4  border-transparent hover:text-yellow hover:border-yellow text-base | md:text-xl | lg:text-2xl"
    >
      {label}
    </NavLink>
  );
}

export default CustomNavLink;
