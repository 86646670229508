import { forwardRef, useId } from 'react';
import Select, { Props as SelectProps } from './select';
import mergeClass from '../utils/mergeClass';
import ErrorMessage from './error-message';
import Label from './label';

interface Props extends SelectProps {
  errorMessage?: string;
  label: string;
}

const SelectInput = forwardRef<HTMLSelectElement, Props>((props, ref) => {
  const {
    label,
    className,
    errorMessage,
    id: id_,
    isError,
    ...rest
  } = props;
  const id = useId();
  return (
    <div className="flex flex-col gap-y-2">
      <Label htmlFor={id}>{label}</Label>
      <Select
        id={id}
        isError={isError}
        className={mergeClass(className)}
        {...rest}
        ref={ref}
      />
      {isError && errorMessage ? (
        <ErrorMessage errorMessage={errorMessage} />
      ) : null}
    </div>
  );
});

export default SelectInput;
