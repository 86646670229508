export const DynamicInputTypes = [
  'Text',
  'Text Area',
  'Email',
  'Rich Text',
  'Password',
  'Number',
  'Select',
  'CheckBox',
  'Radio',
  'Date',
  'Media',
  'Toggle',
  'File',
] as const;

export type DynamicInputTypeKeys = typeof DynamicInputTypes[number];

export type BaseField = {
  label: string;
  isRequired?: boolean;
  isFilterable: boolean;
};
export type FieldType =
  | {
      type: Exclude<DynamicInputTypeKeys, 'Select' | 'Radio' | 'Media'>;
      options?: never;
      isMulti?: never
      limit?: never
    }
  | {
      type: Extract<DynamicInputTypeKeys, 'Select' | 'Radio'>;
      options: { label: string; value: string }[];
      isMulti?: never
      limit?: never
    }
  | {
      type: Extract<DynamicInputTypeKeys, 'Media'>;
      isMulti: boolean;
      limit: number;
      options?: never;
    };

export type Field = BaseField & FieldType;

export type TemplateField = Field & {
  isRequired: boolean;
};

export interface Template {
  _id: string;
  defaultFields: TemplateField[];
  customFields?: TemplateField[];
  name: string;
}

export interface useAllTemplatesReturn {
  data: Template[];
}

export interface TemplateItem {
  _id: string;

  excerpt: string;

  title: string;

  content: string;

  slug: string;

  author: string;

  company: string;

  status: 'draft' | 'published' | 'archived';

  seo: {
    title: string;
    description: string;
    keyword: string;
  };

  customValues?: Record<string, TemplateField & { value: string }>;

  template: string;

  createdAt: string;
}

export type DefaultInputTypes = {
  title: string;
  content: string;
  status: string;
  excerpt: string;
  seo: {
    keyword?: { value: string; label: string; __isNew__?: boolean }[];
    title: string;
    description: string;
  };

  customFields: Record<string, string | FileList | string[]>;

  limits?: Record<string, number>
};
