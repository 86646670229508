import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '../../app/store';

interface AuthState {
  id: null | string,
  token: null | string,
  name: null | string,
  companyId: null | string,
}

const initialState = {
  id: null,
  token: null,
  name: null,
  companyId: null,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState: initialState as AuthState,
  reducers: {
    setCredentials:
      // eslint-disable-next-line max-len
      (state, {
        payload: {
          name, token, id, companyId,
        },
      }: PayloadAction<
        { name: string, token: string, id: string, companyId: string }
      >) => {
        state.id = id;
        state.name = name;
        state.token = token;
        state.companyId = companyId;

        localStorage.setItem('login', JSON.stringify({
          id, name, token, companyId,
        }));
      },
  },
});

export const { setCredentials } = authSlice.actions;

export default authSlice.reducer;

export const selectCurrentUser = (state: RootState) => state.auth.id;
