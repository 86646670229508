/* eslint-disable max-len */

import { SubmitHandler, useForm } from 'react-hook-form';
import { Navigate, useParams } from 'react-router-dom';
import { useTemplateFormSubmit } from '../../hooks/form';
import { useTemplateItem } from '../../hooks/template';
import { DefaultInputTypes } from '../../types/template.type';
import DefaultFields from '../common/default-fields';
import FormRenderer from '../common/form-renderer';

function ArticleView() {
  const { templateItemId } = useParams();
  const {
    data, loading, error, mutate,
  } = useTemplateItem(templateItemId);
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors, isSubmitting },
    control,
    setValue,
  } = useForm<DefaultInputTypes>();

  const { submitHandler } = useTemplateFormSubmit();

  const onSubmit: SubmitHandler<DefaultInputTypes> = async (formData) => {
    await submitHandler({
      data: formData,
      isEditMode: true,
      templateItemId,
      mutate,
    });
  };

  if (loading && !data) {
    return <div>loading...</div>;
  }

  if (!loading && !data && error) {
    return <Navigate to="/template" />;
  }

  return (
    <div className="flex flex-col py-10 items-center">
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="w-3/4 flex flex-col gap-y-4"
      >
        <DefaultFields
          setValue={setValue}
          errors={errors}
          register={register}
          defaultData={data?.data}
          control={control}
        />
        {data?.data.customValues
          ? Object.values(data.data.customValues).map((item) => (
            <FormRenderer
              {...item}
              key={item.label}
              register={register}
              errors={errors}
              setValue={setValue}
              watch={watch}
            />
          ))
          : null}

        <button
          disabled={isSubmitting}
          type="submit"
          className="bg-green-400 uppercase text-white px-8 py-2 my-8 rounded font-semibold flex mx-auto text-center"
        >
          save
        </button>
      </form>
    </div>
  );
}
export default ArticleView;
