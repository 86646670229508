type Application = {
  name: string;
  email: string;
  position: string;
  summery: string;
  resumeUrl:string;
};

type Props = {
  data:Application
}

export default function AppCard(props:Props) {
  const {
    data: {
      name, email, position, summery, resumeUrl,
    },
  } = props;

  return (
    <div className="[&>*]:first-letter:uppercase shadow-gray-300 text-sm flex flex-col justify-around rounded  border-2 border-green-500/10 shadow-lg bg-primary-lighter text-primary-dark p-4 min-h-[15rem]">
      <h1 className="text-2xl font-semibold capitalize">
        {name}
      </h1>
      <p>
        {email}
      </p>
      <p>
        {position}
      </p>
      <p className="">
        {summery}
      </p>

      <a className="self-center" href={resumeUrl}>
        <button
          className="rounded-full bg-primary-dark text-white py-2 px-6 text-sm"
          type="button"
        >
          Download CV
        </button>
      </a>
    </div>
  );
}
