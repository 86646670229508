import { forwardRef, useId } from 'react';
import mergeClass from '../utils/mergeClass';
import ErrorMessage from './error-message';
import Input, { Props as InputProps } from './input';
import Label from './label';

export interface Props extends InputProps {
  errorMessage?: string;
  label: string;
}

const TextInput = forwardRef<HTMLInputElement, Props>((props, ref) => {
  const {
    label,
    className,
    type,
    errorMessage,
    id: id_,
    isError,
    ...rest
  } = props;
  const id = useId();
  return (
    <div className="flex flex-col gap-y-2">
      <div
        className={mergeClass(
          'flex',
          type && type === 'checkbox'
            ? 'flex-row gap-x-2'
            : 'flex-col gap-y-2',
        )}
      >
        <Label htmlFor={id}>{label}</Label>
        <Input
          id={id}
          isError={isError}
          className={mergeClass(className)}
          {...rest}
          ref={ref}
          type={type}
        />
      </div>
      {isError && errorMessage ? (
        <ErrorMessage errorMessage={errorMessage} />
      ) : null}
    </div>
  );
});

export default TextInput;
