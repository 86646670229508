import {
  Control,
  FieldErrorsImpl,
  UseFormRegister,
  UseFormSetValue,
} from 'react-hook-form';
import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';
import { useEffect } from 'react';
import toast from 'react-hot-toast';
import MultiInput from '../../form-prirmitives/multi-input';
import SelectInput from '../../form-prirmitives/select-Input';
import TextInput from '../../form-prirmitives/text-input';
import TextAreaInput from '../../form-prirmitives/textarea-input';
import { DefaultInputTypes, TemplateItem } from '../../types/template.type';
import Label from '../../form-prirmitives/label';
import API from '../../utils/axios';
import { FileUploadResponse } from '../../types/common';

interface defaultFieldsProps {
  register: UseFormRegister<DefaultInputTypes>;
  errors: Partial<FieldErrorsImpl<DefaultInputTypes>>;
  defaultData?: TemplateItem | undefined;
  control: Control<any, any>;
  setValue: UseFormSetValue<DefaultInputTypes>;
}

function DefaultFields(props: defaultFieldsProps) {
  const {
    register, errors, defaultData, control, setValue,
  } = props;

  useEffect(() => {
    register('content');
  }, []);

  useEffect(() => {
    if (defaultData?.content) {
      setValue('content', defaultData.content);
    }
  }, [defaultData]);

  return (
    <>
      <TextInput
        isError={!!errors.title}
        errorMessage={errors.title?.message}
        {...register('title', {
          required: 'this field is required',
          maxLength: {
            message: 'value exceeded maximum length of 60',
            value: 60,
          },
        })}
        label="Title"
        {...(defaultData && { defaultValue: defaultData.title })}
      />
      <TextAreaInput
        isError={!!errors.excerpt}
        errorMessage={errors.excerpt?.message}
        {...register('excerpt', {
          required: 'this field is required',
          maxLength: {
            message: 'value exceeded maximum length of 700',
            value: 700,
          },
        })}
        rows={5}
        label="Excerpt"
        {...(defaultData && { defaultValue: defaultData.excerpt })}
      />

      <div className="flex flex-col gap-y-2">
        <Label htmlFor="content">content</Label>
        <SunEditor
          name="content"
          {...(defaultData && { defaultValue: defaultData.content })}
          setOptions={{
            height: '200',
            buttonList: [['underline', 'bold', 'italic', 'list', 'image']],
          }}
          onChange={(val) => setValue('content', val)}
          onImageUploadBefore={(files, info, uploadHandler) => {
            const loadingToast = toast.loading('Uploading images...', {
              duration: Infinity,
              id: 'image-upload',
            });
            const uploadFormData = new FormData();
            uploadFormData.append('image', files[0], files[0].name);
            const req = API.post<{
              files: FileUploadResponse[];
            }>('/file-upload/many', uploadFormData, {
              headers: {
                'Content-Type': 'multipart/form-data',
              },

            }).then((data) => {
              const res = {
                result: data.data.files.map((item) => ({
                  url: item.fileName,
                  name: item.originalname,
                  size: Number(item.size),
                })),
              };
              uploadHandler(res);
              toast.dismiss(loadingToast);
              toast.success('Image uploaded successfully');
            }).catch((e) => {
              console.error(e);
              toast.dismiss(loadingToast);
              toast.error('An error occurred while uploading images');
            });
          }}

        />
      </div>

      <SelectInput
        {...register('status', { required: 'this field is required' })}
        isError={!!errors.status}
        errorMessage={errors.status?.message}
        label="Status"
        {...(defaultData && { defaultValue: defaultData.status })}
      >
        <option value="">Select a value</option>
        <option value="draft">Draft</option>
        <option value="published">Published </option>
        <option value="archived">Archived</option>
      </SelectInput>
      <TextInput
        isError={!!errors.seo?.title}
        errorMessage={errors.seo?.title?.message}
        {...register('seo.title', {
          maxLength: {
            message: 'value exceeded maximum length of 60',
            value: 60,
          },
        })}
        label="SEO title"
        {...(defaultData && {
          defaultValue: defaultData.seo.title,
        })}
      />
      <MultiInput
        label="SEO keywords"
        control={control}
        name="seo.keyword"
        {...(defaultData && { defaultValue: defaultData.seo.keyword })}
      />
      <TextAreaInput
        isError={!!errors.seo?.description}
        errorMessage={errors.seo?.description?.message}
        {...register('seo.description', {
          maxLength: {
            message: 'value exceeded maximum length of 165',
            value: 165,
          },
        })}
        rows={5}
        label="SEO description"
        {...(defaultData && {
          defaultValue: defaultData.seo.description,
        })}
      />
    </>
  );
}

export default DefaultFields;
