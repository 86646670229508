import { ComponentProps, forwardRef } from 'react';
import mergeClass from '../utils/mergeClass';

export interface Props extends ComponentProps<'input'> {
  isError?: boolean;
}

const Input = forwardRef<HTMLInputElement, Props>((props, ref) => {
  const {
    className, isError, type, ...rest
  } = props;
  return (
    <input
      className={mergeClass(
        type === 'checkbox'
          ? 'form-checkbox  h-5 w-5 rounded border-gray-300'
          : 'form-input',
        'form-input shadow-sm sm:text-sm  rounded-md',
        isError
          ? 'focus:ring-red-500 focus:border-red-500 border-red-500'
          : 'focus:ring-indigo-500 focus:border-indigo-500 border-gray-300',
        className,
      )}
      type={type}
      ref={ref}
      {...rest}
    />
  );
});

export default Input;
