import { Outlet } from 'react-router-dom';
import Sidebar from '../components/sidebar/Sidebar';

function Layout() {
  return (
    <div className="grid grid-cols-12 overflow-hidden h-screen">
      <nav className="col-span-4 bg-primary-dark min-h-screen | md:col-span-3 | lg:col-span-2">
        <Sidebar />
      </nav>
      <main className="col-span-8 overflow-y-scroll | md:col-span-9 | lg:col-span-10">
        <Outlet />
      </main>
    </div>
  );
}

export default Layout;
