/* eslint-disable react/no-array-index-key */
import { FiPlus } from 'react-icons/fi';
import { toast } from 'react-hot-toast';
import {
  useForm,
  SubmitHandler,
  useFieldArray,
  UseFieldArrayAppend,
  UseFieldArrayRemove,
} from 'react-hook-form';
import TextInput from '../form-prirmitives/text-input';
import Button from '../form-prirmitives/button';
import SelectInput from '../form-prirmitives/select-Input';
import MultiInput from '../form-prirmitives/multi-input';
import {
  Field,
  DynamicInputTypeKeys,
  DynamicInputTypes,
} from '../types/template.type';
import API from '../utils/axios';
import { useAllTemplates } from '../hooks/template';
import Label from '../form-prirmitives/label';

interface Inputs {
  name: string;
  description?: string;
  customField: Field[];
}

interface DefaultFields {
  primary: Field[];
  seo: Field[];
}

const defaultFields: DefaultFields = {
  primary: [
    { label: 'Title', type: 'Text', isFilterable: false },
    { label: 'Content', type: 'Text', isFilterable: false },
    { label: 'Slug', type: 'Text', isFilterable: false },
    { label: 'Author', type: 'Text', isFilterable: false },
    { label: 'Status', type: 'Text', isFilterable: false },
  ],
  seo: [
    { label: 'Keywords', type: 'Text', isFilterable: false },
    { label: 'Description', type: 'Text', isFilterable: false },
    { label: 'Title', type: 'Text', isFilterable: false },
  ],
};

type ShowButton =
  | {
      showDeleteButton: true;
      remove: UseFieldArrayRemove;
      id: number;
    }
  | { showDeleteButton: false | undefined; remove?: never; id?: never };

type FieldDisplayProps = {
  label: string;
  type: DynamicInputTypeKeys;
} & ShowButton;

function FieldDisplay(props: FieldDisplayProps) {
  const {
    label, showDeleteButton, id, remove, type,
  } = props;
  return (
    <div className="border border-gray-300 p-4">
      <div className="grid grid-cols-3 items-center">
        <p>{label}</p>
        <p className="text-center">{type}</p>
        <div className="flex items-center justify-end">
          {showDeleteButton ? (
            <Button type="button" onClick={() => remove(id)}>
              Delete
            </Button>
          ) : null}
        </div>
      </div>
    </div>
  );
}

type FieldRendererProps = Field & ShowButton;

function FieldRenderer(props: FieldRendererProps) {
  const { options, ...rest } = props;

  let comp;
  switch (options) {
    case undefined:
      comp = <FieldDisplay {...rest} />;
      break;

    default:
      comp = <FieldDisplay {...rest} />;
      break;
  }

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{comp}</>;
}

interface DynamicInputs {
  inputLabel: string;
  inputType: DynamicInputTypeKeys;
  options: { label: string; value: string }[];
  isRequired: boolean;
  isFilterable: boolean;
  isMulti: boolean;
  limit: number;
}

interface DynamicFormProps {
  append: UseFieldArrayAppend<
    Inputs,
    'customField' | `customField.${number}.options`
  >;
}

function DynamicForm(props: DynamicFormProps) {
  const { append } = props;
  const {
    register,
    handleSubmit,
    watch,
    control,
    formState: { errors },
    reset,
  } = useForm<DynamicInputs>();
  const onSubmit: SubmitHandler<DynamicInputs> = (data) => {
    const options = data.options && data.options.length
      ? data.options.map((item) => ({ label: item.label, value: item.value }))
      : undefined;

    switch (data.inputType) {
      case 'Select':
        if (options !== undefined) {
          append({
            isFilterable: data.isFilterable,
            label: data.inputLabel,
            type: data.inputType,
            isRequired: data.isRequired,
            options,
          });
        }
        break;
      case 'Radio':
        if (options !== undefined) {
          append({
            isFilterable: data.isFilterable,
            label: data.inputLabel,
            type: data.inputType,
            isRequired: data.isRequired,
            options,
          });
        }
        break;

      case 'Media':
        append({
          label: data.inputLabel,
          type: data.inputType,
          isRequired: data.isRequired,
          isFilterable: data.isFilterable,
          isMulti: data.isMulti,
          limit: data.limit,
        });
        break;

      default:
        append({
          label: data.inputLabel,
          type: data.inputType,
          isRequired: data.isRequired,
          isFilterable: data.isFilterable,
        });
        break;
    }
    reset();
  };
  const inputType = watch('inputType');
  const shouldShowOptions = inputType === 'Select' || inputType === 'Radio';
  const shouldShowMulti = inputType === 'Media';
  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="pt-10 flex flex-col gap-y-4"
    >
      <div className="flex flex-col">
        <h1>Add Custom Fields</h1>
      </div>

      <div className="flex flex-col gap-y-2">
        <TextInput
          isError={!!errors.inputLabel}
          errorMessage={errors.inputLabel?.message}
          label="Name"
          {...register('inputLabel', { required: 'this field is required' })}
        />

        <SelectInput
          isError={!!errors.inputType}
          errorMessage={errors.inputType?.message}
          label="Type"
          {...register('inputType', { required: 'this field is required' })}
        >
          <option value="">select input type</option>
          {DynamicInputTypes.map((item) => (
            <option key={item} value={item}>
              {item}
            </option>
          ))}
        </SelectInput>

        <div className="flex items-center gap-x-3 py-3">
          <Label htmlFor="is-required">Is required</Label>
          <input
            id="is-required"
            type="checkbox"
            defaultChecked
            className="form-checkbox h-5 w-5 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
            {...register('isRequired')}
          />
        </div>

        <div className="flex items-center gap-x-3 py-3">
          <Label htmlFor="is-required">Is filterable</Label>
          <input
            id="is-filterable"
            type="checkbox"
            className="form-checkbox h-5 w-5 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
            {...register('isFilterable')}
          />
        </div>

        {shouldShowOptions ? (
          <MultiInput
            rules={{ required: 'this field is required' }}
            label="options"
            control={control}
            name="options"
          />
        ) : null}

        {shouldShowMulti ? (
          <>
            <div className="flex items-center gap-x-3 py-3">
              <Label htmlFor="is-required">Is Multi file input</Label>
              <input
                id="isMulti"
                type="checkbox"
                className="form-checkbox h-5 w-5 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                {...register('isMulti')}
              />
            </div>

            <TextInput
              isError={!!errors.limit}
              errorMessage={errors.limit?.message}
              label="No of images"
              {...register('limit', {
                required: 'this field is required',
                min: { value: 1, message: 'min value should be greater than 1' },
              })}
              defaultValue={1}
            />
          </>
        ) : null}
      </div>

      <div className="flex items-center justify-end">
        <Button
          fullWidth
          className="flex items-center justify-center gap-x-2"
          type="submit"
        >
          <FiPlus aria-hidden className="h-5 w-5" />
          {' '}
          Add
        </Button>
      </div>
    </form>
  );
}

function AddTemplate() {
  const { mutate } = useAllTemplates();
  const {
    register,
    handleSubmit,
    control,
    formState: { errors, isSubmitting },
  } = useForm<Inputs>();
  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    const postData = { ...data } as Partial<Inputs>;
    if (postData.customField && !postData.customField.length) {
      delete postData.customField;
    }

    try {
      await API.post('/templates', postData);
      toast.success('Success: template added');
      mutate();
    } catch (err) {
      toast.error('Error: Bad Request');
      console.log(err);
    }
  };

  const { fields, append, remove } = useFieldArray<Inputs>({
    control,
    name: 'customField',
  });

  return (
    <div className="py-10 h-screen">
      <div className="flex flex-col items-center">
        <div className='flex flex-col  w-5/12 px-4 py-5 sm:p-6  shadow sm:rounded-md"'>
          <form
            id="article-form"
            className="space-y-4 "
            onSubmit={handleSubmit(onSubmit)}
          >
            <h1 className="font-semibold pb-4 capitalize text-2xl text-center">
              Create template for an article
            </h1>

            <TextInput
              isError={!!errors.name}
              errorMessage={errors.name?.message}
              label="Template name"
              {...register('name', { required: 'this field is required' })}
            />
            <TextInput label="Description" {...register('description')} />

            <div className="flex flex-col">
              <h1>Default fields</h1>
              <p className="text-sm text-gray-500">
                these are required fields which are enabled by default
              </p>
              <div className="py-3">
                <hr />
              </div>
              <div className="flex flex-col gap-y-8">
                <div>
                  <h2 className="text-sm">Primary</h2>
                  <div className="flex flex-col gap-y-2 pt-4">
                    {defaultFields.primary.map((item) => (
                      <FieldRenderer
                        showDeleteButton={false}
                        key={item.label}
                        {...item}
                      />
                    ))}
                  </div>
                </div>
                <div>
                  <h2 className="text-sm">Seo</h2>
                  <div className="flex flex-col gap-y-2 pt-4">
                    {defaultFields.seo.map((item) => (
                      <FieldRenderer
                        showDeleteButton={false}
                        key={item.label}
                        {...item}
                      />
                    ))}
                  </div>
                </div>
                {fields.length ? (
                  <div>
                    <h2 className="text-sm">Custom Fields</h2>

                    {fields.map(({ id, ...rest }: any, index) => (
                      <FieldRenderer
                        remove={remove}
                        id={index}
                        key={id}
                        {...rest}
                        showDeleteButton
                      />
                    ))}
                  </div>
                ) : null}
              </div>
            </div>
          </form>

          <DynamicForm append={append} />
        </div>

        {/* TODO add button component  */}
        <button
          disabled={isSubmitting}
          type="submit"
          form="article-form"
          className="bg-blue-400 px-16 py-2 my-8 rounded text-white font-semibold mx-auto disabled:bg-gray-400 disabled:text-gray-600"
        >
          Submit
        </button>
      </div>
    </div>
  );
}

export default AddTemplate;
