import { ComponentProps, forwardRef } from 'react';
import mergeClass from '../utils/mergeClass';

export type TextAreaProps = ComponentProps<'textarea'> & { isError?: boolean };

const TextArea = forwardRef<HTMLTextAreaElement, TextAreaProps>(
  (props, ref) => {
    const { className, isError, ...rest } = props;
    return (
      <textarea
        ref={ref}
        className={mergeClass(
          'form-textarea w-full shadow-sm sm:text-sm  rounded-md',
          isError
            ? 'focus:ring-red-500 focus:border-red-500 border-red-500'
            : 'focus:ring-indigo-500 focus:border-indigo-500 border-gray-300',
          className,
        )}
        {...rest}
      />
    );
  },
);

export default TextArea;
