/* eslint-disable react/no-array-index-key */
/* eslint-disable no-underscore-dangle */

import { ChangeEvent, useState } from 'react';
import { Link, Navigate, useParams } from 'react-router-dom';

import ArticleCard from '../components/common/cards/ArticleCard';
import SelectInput from '../form-prirmitives/select-Input';
import { useAllTemplateItems, useTemplate } from '../hooks/template';

function ArticlePage() {
  const [filter, setFilter] = useState<Record<string, string>>({});
  const { templateId } = useParams();
  const { data: template } = useTemplate(templateId);
  const { data, error, loading } = useAllTemplateItems(templateId, filter);

  if (loading && !data) {
    return <div>loading...</div>;
  }

  if (!loading && !data && error) {
    return <Navigate to="/template" />;
  }

  const renderArticles = data?.data.map((item) => (
    <Link key={item._id} to={`/template-item/${item._id} ` || ''}>
      <ArticleCard key={item._id} article={item} />
    </Link>
  ));

  const filterFields = template?.data?.customFields?.filter(
    (item) => item.isFilterable,
  );

  const handleChange = (e: ChangeEvent<HTMLSelectElement>) => {
    setFilter((prev) => {
      const prevValue = { ...prev };

      if (e.target.value !== '') {
        prevValue[e.target.name] = e.target.value;
      } else if (prevValue[e.target.name]) {
        delete prevValue[e.target.name];
      }

      return prevValue;
    });
  };

  return (
    <div className="flex flex-col py-10 px-20 w-full">
      <Link
        to="new"
        className="bg-primary-dark max-w-fit px-6 py-2 mb-8 rounded-full text-white self-end"
      >
        <button type="button" className="font-semibold capitalize">
          create new
          {' '}
          {template?.data.name}
        </button>
      </Link>

      <div className="flex gap-x-2 items-center w-full pt-10">
        <div className="min-w-[12rem]">
          <SelectInput
            onChange={handleChange}
            name="createdAt"
            label="Sort By Created"
            value={filter.createdAt}
          >
            <option value="">select a value</option>
            <option value="1">Ascending</option>
            <option value="-1">Descending</option>
          </SelectInput>
        </div>
        {filterFields && filterFields.length
          ? filterFields.map((item) => (item.type === 'Select' ? (
            <div key={item.label} className="min-w-[12rem]">
              <SelectInput
                onChange={handleChange}
                name={item.label}
                label={`Filter By ${item.label
                  .toLowerCase()
                  .replace(/\w/, (firstLetter) => firstLetter.toUpperCase())}`}
                value={filter[item.label]}
              >
                <option value="">
                  all
                  {' '}
                  {item.label}
                </option>
                {item.options.map((option) => (
                  <option key={option.label} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </SelectInput>
            </div>
          ) : null))
          : null}
      </div>
      <div className="grid grid-cols-2 pt-8 gap-10">{renderArticles}</div>
    </div>
  );
}
export default ArticlePage;
