import { useSelector } from 'react-redux';
import { Navigate, Outlet } from 'react-router-dom';
import { RootState } from '../app/store';

const useAuth = () => {
  const user = useSelector((state: RootState) => state.auth.id);
  if (user) { return true; }
  return false;
};

function PrivateRoute() {
  const auth = useAuth();
  return (
    auth ? <Outlet /> : <Navigate to="/login" />
  );
}

export default PrivateRoute;
